// import XLSX from "xlsx";
import { utils, writeFile } from "xlsx";
function downloadExcel(tableData, columns, fileName) {
  const json = tableData.map((item) => {
    const newItem = {};
    columns.forEach((column) => {
      newItem[column.label] = item[column.prop];
    });
    return newItem;
  });
  const sheet = utils.json_to_sheet(json);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, sheet, "导入数据");
  writeFile(wb, fileName);
}

export default downloadExcel;
