import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function getM8Setting(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/settings",
    method: "get",
    params
  });
}

export function getPriceTypes() {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/settings/price-types",
    method: "get",
  });
}

export function getProperties() {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/settings/property",
    method: "get",
  });
}

export function getStore() {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/settings/store",
    method: "get",
  });
}

export function updateSetting(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/settings",
    method: "post",
    data
  });
}

export function getPartUpList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/up",
    method: "get",
    params
  });
}

export function getPartDownList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/down",
    method: "get",
    params
  });
}

export function getPartProcessingList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/processing",
    method: "get",
    params
  });
}

export function getPartFailLogsList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/logs/list-faild",
    method: "get",
    params
  });
}

export function authParts(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/auth",
    method: "post",
    data
  });
}

export function toPlatformParts(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/to-platform",
    method: "post",
    data
  });
}

export function deAuthParts(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.m8Module,
    url: "/part/deauth",
    method: "post",
    data
  });
}

export function getLabelList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/labels/list",
    method: "get",
    params
  });
}