import request, {
  SERVICE_MODULE_MAP
} from '@/utils/request';

// 商品列表
export function queryGoods(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/list',
    method: 'get',
    params,
  });
}

export function getGoodsInfo(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/' + params.swId,
    method: 'get',
    params,
  });
}

// 编辑商品
export function editGoods(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/' + data.id,
    method: 'post',
    data,
  });
}

// 上架配件
export function companyPartsUp(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/company-parts',
    method: 'post',
    data,
  });
}

// 配件同步价格
export function companySkuPrices(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/company-skus/prices',
    method: 'post',
    data,
  });
}

// 配件同步库存
export function companySkuQty(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/company-skus/qty',
    method: 'post',
    data,
  });
}

// 仓库库存价格
export function companyWarehouseStock(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/company-warehouse-stocks',
    method: 'post',
    data,
  });
}


export function batchUpdateGoods(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: '/goods/company-warehouse-stocks',
    method: 'post',
    data,
  });
}