<template>
  <import-box
    :visible="relVisible"
    v-on="$listeners"
    title="商品导入"
    remakTitle="商品导入说明"
    :hasSetting="importInfo.hasSetting"
    :offset="importInfo.offset"
    :options="importInfo.options"
    :isAllFileImport="importInfo.isAllFileImport"
    :reqHandler="reqHandle"
    :replaceData="ruleForm"
    :on-import="handleOnImport"
    importName="goods-import"
  >
    <el-form
      size="mini"
      label-position="left"
      class="form-item-small-margin-bottom"
    >
      <el-form-item
        label-width="350px"
        label="已存在相同的编码、品牌、产地的商品，更新商品资料"
      >
        <el-switch
          v-model="ruleForm.updatePart"
          active-color="#6589F8"
          :class="[ruleForm.updatePart ? 'yes' : 'no']"
        >
        </el-switch>
      </el-form-item>

      <!-- <el-form-item label-width="0">
        <div class="item-container">
          <el-checkbox v-model="ruleForm.isAssignBrand" />
          <el-form-item
            label="指定品牌"
            label-width="70px"
            class="item-content"
          >
            <ht-autocomplete
              v-model="ruleForm.assignBrand"
              :disabled="!this.ruleForm.isAssignBrand"
              :fetch-suggestions="this.autocompleteSearchPartBrand"
              :trigger-on-focus="false"
              placeholder="请选择"
              highlight-first-item
              clearable
            />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label-width="0">
        <div class="item-container">
          <el-checkbox v-model="ruleForm.isAssignProductionPlace" />
          <el-form-item
            label="指定产地"
            label-width="70px"
            class="item-content"
          >
            <ht-autocomplete
              v-model="ruleForm.assignProductionPlace"
              :fetch-suggestions="autocompleteSearchProductionPlace"
              :disabled="!ruleForm.isAssignProductionPlace"
              :trigger-on-focus="false"
              placeholder="请选择"
              highlight-first-item
              clearable
            />
          </el-form-item>
        </div>
      </el-form-item> -->
    </el-form>
    <div slot="remark" class="remark-container">
      <div class="remark-title">商品导入说明</div>
      <br />
      <div class="remark-label">数据要求：</div>
      1、导入数据必须需包含：配件编码、配件名称。
      <br />
      <br />
      <div class="remark-label">数据说明：</div>
      1、在导入时每一行数据都将按照配件编码、品牌和产地这3个字段与系统所存储的配件数据进行匹配，3个字段完全一致才会匹配成功。如果您的配件资料中有使用品牌和产地这两个字段，请在导入时也要加上这两个字段的数据
      <br />
      2、如果导入数据未选择品牌、产地数据系统默认设置该值为空。
      <br />
      <br />
      <div class="remark-label">导入设置说明：</div>
      1、若开启“已存在相同的编码、品牌、产地的配件，更新配件资料”，则系统将自动与本地配件资料进行对比判断，若存在该配件，则将资料更新为导入数据。
      <br />
      <br />
      <div class="remark-label">文件要求：</div>
      1、数据文件格式为Excel文件支持的后缀为.xlsx .xls且需选择对应工作表
      <br />
      2、数据读取完成后，给上传的文件字段选择对应的目标字段
      <br />
      要求：必须一一对应且不重复
      <br />
      3、Excel文件，格式要求如下：
      <br />
      （1）第一行表示列标题，不是正式数据
      <br />
      （2）不能存在合并单元格
      <br />
      （3）只能有文本，数字，日期等文字信息，不能含有图形，图像等非文字信息
    </div>
  </import-box>
</template>

<script>
import ImportBox from "@/components/importBox";
import { reqImportParts } from "@/api/importBox";

const defaultRuleForm = {
  updatePart: false,
  // isAssignBrand: false,
  // assignBrand: "",
  // isAssignProductionPlace: false,
  // assignProductionPlace: "",
};

export default {
  name: "ImportPartsBox",
  components: { ImportBox },
  props: {
    importInfo: {
      type: Object,
      required: true,
    },
    visible: Boolean,
  },
  data() {
    return {
      ruleForm: { ...defaultRuleForm },
    };
  },
  computed: {
    relVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.initData();
      } else {
        this.relVisible = val;
      }
    },
  },
  methods: {
    initData() {
      this.ruleForm = { ...defaultRuleForm };
    },
    reqHandle(params) {
      // console.log("params", params);
      return new Promise((resolve, reject) => {
        reqImportParts({
          updatePart: this.ruleForm.updatePart,
          // assignBrand:
          //   this.ruleForm.isAssignBrand && this.ruleForm.assignBrand
          //     ? this.ruleForm.assignBrand
          //     : undefined,
          // assignProductionPlace:
          //   this.ruleForm.isAssignProductionPlace &&
          //   this.ruleForm.assignProductionPlace
          //     ? this.ruleForm.assignProductionPlace
          //     : undefined,
          parts: params,
        })
          .then((data) => {
            resolve(data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    handleOnImport() {
      this.$emit("import");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  &::v-deep .el-checkbox {
    margin-right: 10px;
  }
}
.item-content {
  flex: 1;
}
.el-switch {
  &.yes {
    position: relative;
    &::before {
      content: "是";
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 12px;
      color: #ffffff;
    }
  }
  &.no {
    position: relative;
    /deep/.el-switch__core {
      border: 1px solid #6589f8;
      background: #ffffff;
      &::after {
        background-color: #6589f8;
      }
    }
    &::after {
      content: "否";
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      font-size: 12px;
      color: #6589f8;
    }
  }
}
.remark-container {
  height: 580px;
  padding: 10px;
  background: #f2f2f2;
  font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
  font-weight: 400;
  color: #7f7f7f;
  font-size: 13px;
  .remark-title {
    font-family: "PingFangSC-Medium", "PingFang SC Medium", "PingFang SC",
      sans-serif;
    font-weight: 500;
    color: #f59a23;
    font-size: 14px;
  }
  .remark-label {
    color: #333333;
  }
}
// .form-item-small-margin-bottom {
//   .el-form-item--mini.el-form-item{
//     margin-bottom: 4px;
//   }
// }
</style>
