<template>
  <!-- 导入组件 -->
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :show-close="!importLoading"
    width="1000px"
  >
    <div v-loading="importLoading" class="flex-column">
      <el-form size="mini" label-position="left">
        <el-row>
          <el-col :span="16">
            <el-form-item label-width="82px" label="数据文件：">
              <input
                ref="refExceptInput"
                type="file"
                accept=".xlsx, .xlc, .xlm, .xls, .xlt, .xlw, .csv"
                class="import-file"
                @change="importExcel($event.target.files)"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label-width="100px" label="选择工作表：">
              <el-select v-model="curSheet" @change="selectSheet">
                <el-option
                  v-for="(item, index) in excelSheetsName"
                  :key="index"
                  :value="item"
                  :label="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item class="placeholder-form-item">
              <br class="placeholder-form-item" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="0px">
              <el-button type="primary" size="mini" @click="clearMappingField">
                清空映射字段
              </el-button>
              <el-button type="primary" size="mini" @click="setMappingField">
                自动关联映射字段
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="10" class="import-container flex1">
        <el-col v-show="hasSetting" :span="12" class="import-setting-container">
          <div>
            <el-container>
              <el-header>导入设置</el-header>
              <el-main>
                <slot />
              </el-main>
            </el-container>
          </div>
        </el-col>
        <el-col :span="hasSetting ? 12 : 24" style="height: 100%">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="字段映射" name="first">
              <el-container style="height: calc(100% - 60px)">
                <el-header>
                  <span style="color: #6bd3fd">
                    注：目标字段为蓝色表示必须要关联字段
                  </span>
                </el-header>
                <el-main>
                  <import-table
                    ref="refImportTable"
                    :requiredField="requiredField"
                    :mappingData="mappingData"
                    :mappingSelectOptions="mappingSelectOptions"
                    @updateMappingData="updateMappingData"
                    v-bind="$attrs"
                  />
                </el-main>
              </el-container>
            </el-tab-pane>
            <el-tab-pane v-if="remakTitle" label="操作指南" name="second">
              <el-container style="height: 100%">
                <slot name="remark" />
              </el-container>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <ImportResult
        :visible.sync="completeVisible"
        :total-errs="errRows"
        :resultColumn="resultColumn"
        :rowsErrInfo="rowsErrInfo"
        :total-count="totalCount"
        v-bind="$attrs"
      />
    </div>
    <div slot="footer" class="text-center mt-8">
      <el-button size="small" @click="cancelHandle">
        取消
      </el-button>
      <el-button
        :disabled="!isCanClickImport"
        type="primary"
        size="small"
        @click="importHandle"
      >
        确定
      </el-button>
    </div>
    <el-dialog
      :visible.sync="imporProgressVisiable"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
      width="70%"
    >
      <div style="height: 150px; text-align: center;">
        <p>导入中，请耐心等待......</p>
        <el-progress
          :text-inside="true"
          :stroke-width="22"
          :percentage="imporProgress"
        />
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import ImportTable from "./components/importTable";
import ImportResult from "./components/importResult";

import { utils } from "xlsx";
// import { dateFormat, dateFormatToSecond } from "@/utils/date";

import { getSettingsTables, postSettingsTables } from "@/api/importBox";

import { envConfig } from "envConfigPath";

let excelSheets = {};
let totalData = [];

export default {
  name: "ImportDialog",
  components: {
    ImportTable,
    ImportResult,
  },
  props: {
    hasSetting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    offset: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isCanClickImport: {
      type: Boolean,
      default: true,
    },
    reqHandler: {
      type: Function,
      required: true,
    },
    remakTitle: {
      type: String,
      default: "",
    },
    isBill: Boolean,
    toValidIncludeOptions: {
      type: Object,
      default: null,
    },
    importName: {
      type: String,
      required: true,
    },
    replaceData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      importLoading: false,
      excelSheetsName: [],
      curSheet: "",
      fileDataJson: [],

      // selections: {},
      totalCount: 0,
      errRows: [],
      completeVisible: false,
      imporProgress: 0,
      localScope: null,

      activeName: "first",
      mappingData: [],
      mappingSelectOptions: [],
      fileDataJsonKeys: [],
      resultColumn: [],
      rowsErrInfo: [],
    };
  },
  computed: {
    imporProgressVisiable() {
      return !!this.imporProgress;
    },
    requiredField() {
      return this.options
        .filter((item) => item.required)
        .map((item) => item.label);
    },
  },
  watch: {
    "$attrs.visible"(now) {
      if (now) {
        this.initMappingData();
        this.getMappingField();
      } else {
        this.clearHandle();
      }
    },
  },
  methods: {
    initMappingData() {
      this.mappingData = [];
      if (this.options.length > 0) {
        let obj = {};
        this.options.forEach((item) => {
          obj = {
            targetField: item.label,
            sourceField: "",
          };
          this.mappingData.push(obj);
        });
        // console.log("mappingData0", this.mappingData);
      }
    },
    clearMappingField() {
      this.initMappingData();
    },
    setMappingField() {
      this.initMappingData();
      // console.log("fileDataJsonKeys", this.fileDataJsonKeys);
      this.mappingData = this.mappingData.map((item) => {
        if (this.fileDataJsonKeys.includes(item.targetField)) {
          return {
            ...item,
            sourceField: item.targetField,
          };
        } else {
          return item;
        }
      });
      // console.log("mappingData1", this.mappingData);
    },
    getMappingField() {
      getSettingsTables({
        name: this.importName,
      }).then((res) => {
        if (res?.value) {
          this.mappingData = res.value;
        }
      });
    },
    importExcel(files) {
      if (!files.length) {
        this.curSheet = "";
        this.excelSheetsName = [];
        this.fileDataJson = [];
        return;
      }
      const file = files[0] || {};
      if (!file) {
        this.curSheet = "";
        this.excelSheetsName = [];
        this.fileDataJson = [];
        return;
      }
      const types = file.name.split(".")[file.name.split(".").length - 1],
        fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
          (item) => item === types
        );
      if (!fileType) {
        this.$message.warning({
          message: "文件格式错误！请重新选择",
          showClose: true,
        });
        return;
      }
      this.file2Xce(file).then(async (data) => {
        this.importLoading = true;
        const fullConfig = envConfig;
        const routerPrefix = fullConfig.routerPrefix
          ? fullConfig.routerPrefix + "/worker/parseExcel.js"
          : location.origin + "/worker/parseExcel.js";
        const worker = new Worker(routerPrefix);
        let wb;
        worker.onmessage = (e) => {
          switch (e.data.t) {
            case "ready":
              break;
            case "e":
              this.importLoading = false;
              console.error(e.data.d);
              break;
            case "xlsx":
              this.importLoading = false;
              wb = JSON.parse(e.data.d);
              excelSheets = wb.Sheets;
              this.excelSheetsName = wb.SheetNames;
              this.curSheet = this.excelSheetsName[0];
              this.selectSheet(this.curSheet);
              break;
          }
        };
        worker.postMessage({
          d: data,
          b: { type: "binary" },
          t: "xlsx",
        });
      });
    },
    file2Xce(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          resolve(data);
        };
        reader.readAsBinaryString(file);
      });
    },
    updateMappingData(mappingTableData) {
      this.mappingData = JSON.parse(JSON.stringify(mappingTableData));
    },
    selectSheet(sheet) {
      let merges = [];
      merges = excelSheets[sheet]["!merges"] || [];
      if (merges && merges.length > 0) {
        this.$message.warning({
          message: "导入Excel不能包含合并单元格",
          showClose: true,
        });
        return;
      }
      const fileDataJson = utils.sheet_to_json(excelSheets[sheet], {
        raw: false,
        defval: "",
      });
      // 来源字段可选的下拉来自于文件的所有列
      this.mappingSelectOptions = [];
      this.fileDataJsonKeys = [];
      this.fileDataJsonKeys = [...Object.keys(fileDataJson[0])];
      for (let i = 0; i < this.fileDataJsonKeys.length; i++) {
        const obj = {
          id: i,
          value: this.fileDataJsonKeys[i],
        };
        this.mappingSelectOptions.push(obj);
      }

      // console.log("fileDataJson", fileDataJson);
      // console.log("fileDataJsonKeys", this.fileDataJsonKeys);
      // console.log("mappingSelectOptions", this.mappingSelectOptions);

      if (fileDataJson && fileDataJson.length > 0) {
        this.fileDataJson = fileDataJson.map((item) => ({ ...item }));
        totalData = fileDataJson.map((item) => ({ ...item }));
        this.totalCount = totalData.length;
        // this.selections = {};
      }

      // console.log("utils", utils);
      // console.log("this.fileDataJson", this.fileDataJson);
      // console.log("this.totalCount", this.totalCount);
    },

    async importHandle() {
      console.log("importHandle======222");
      if (!this.curSheet) {
        this.$message.warning({
          message: "请选择导入文件",
          showClose: true,
        });
        return;
      }
      if (this.checkMappingRelationship()) {
        // 如果有字段要求：要么有映射关系，要么有指定，那么就要传此方法去验证是否满足条件
        this.importLoading = true;
        const paramRows = [];
        this.resultColumn = [];
        // 组合数据传给后端 fileDataJson = {我的编码:"001"}，mappingData=[{targetField:"配件编码",sourceField:"我的编码"}]，options=[{label:"配件编码",value:"code"}]，最后组合成paramRows=[{code:"001"}]
        // ，mappingData和options能确定paramRows里面对象的key（也就是code字段），fileDataJson和mappingData能确定paramRows里面对象的value（也就是001）
        this.fileDataJson.forEach((item) => {
          const obj = {};
          this.mappingData.forEach((ele) => {
            // 将有映射字段的值筛选出来
            if (ele.sourceField != "") {
              const filterData = this.options.filter(
                (data) => data.label == ele.targetField
              );
              if (
                !this.resultColumn.some(
                  (item) => item.label == filterData[0].label
                )
              ) {
                this.resultColumn.push(filterData[0]);
              }
              if (filterData.length > 0) {
                obj[filterData[0].value] = item[ele.sourceField];
              }
            }
          });
          paramRows.push(obj);
        });

        const paramRowsParams = paramRows.map((item, index) => {
          item = {
            ...item,
            rowNumber: index + 1,
          };
          return item;
        });

        this.reqHandle(paramRowsParams)
          .then((errors) => {
            // 保存映射关系
            postSettingsTables({
              name: this.importName,
              value: this.mappingData,
            })
              .then()
              .catch((err) => {
                console.log("err", err);
              });
            // 保存form里面是Switch开关的条件
            this.$emit("setImportSwitch");
            // 刷新页面数据
            this.$emit("import", true);
            console.log("reqHandle===", errors, paramRowsParams);
            this.errRows = [
              ...this.getRowsErrInfo({ ...errors }, [...paramRowsParams]),
            ].filter((item) => item.errors && item.errors.length > 0);
            this.cancelHandle();
            this.imporProgress = 0;
            this.completeVisible = true;
          })
          .catch((err) => {
            console.log("err===catch", err);
            this.imporProgress = 0;
            if (err?.message) {
              this.$alert(
                `<div><div>导入异常，请联系思锐客服协助处理。</div><div>出错信息:${err.message}</div></div>`,
                "导入异常",
                {
                  showConfirmButton: false,
                  dangerouslyUseHTMLString: true,
                }
              );
            }
            console.log("reqHandle===catch", err);
          })
          .finally(() => {
            this.importLoading = false;
          });
      }
    },
    async reqHandle(rows) {
      if (this.isBill && this.offset) {
        const resData = [];
        const count = Math.ceil(rows.length / this.offset);
        const offset = Math.floor(100 / count);
        if (count > 1) {
          try {
            const res1 = await this.reqHandler(rows.slice(0, this.offset));
            resData.push(res1?.errors || []);
          } catch (err) {
            console.log("try-catch===1", err);
            throw err;
          }
          for (let i = 0; i < count; i++) {
            const params = rows.slice(
              (i + 1) * this.offset,
              (i + 2) * this.offset
            );
            try {
              const res = await this.reqHandler(params);
              this.imporProgress += offset;
              resData.push(res?.errors || []);
            } catch (err) {
              console.log("try-catch===2", err);
              throw err;
            }
          }
        } else {
          try {
            const res1 = await this.reqHandler(rows);
            resData.push(res1?.errors || []);
          } catch (err) {
            console.log("try-catch===3", err);
            throw err;
          }
        }
        let errors = {};
        resData.forEach((item) => {
          errors = { ...errors, ...item };
        });
        return errors;
      } else if (this.offset) {
        const resData = [];
        const count = Math.ceil(rows.length / this.offset);
        const offset = Math.floor(100 / count);
        for (let i = 0; i < count; i++) {
          const params = rows.slice(i * this.offset, (i + 1) * this.offset);
          try {
            const res = await this.reqHandler(params);
            this.imporProgress += offset;
            resData.push(res?.errors || []);
          } catch (err) {
            console.log("try-catch===4", err);
            throw err;
          }
        }
        let errors = {};
        resData.forEach((item) => {
          errors = { ...errors, ...item };
        });
        return errors;
      } else {
        try {
          const res = await this.reqHandler(rows);
          return res?.errors || [];
        } catch (err) {
          console.log("try-catch===5", err);
          throw err;
        }
      }
    },
    getRowsErrInfo(errors, rows) {
      this.rowsErrInfo = [];
      if (rows && rows.length) {
        rows.forEach((item) => {
          const newItem = {
            ...item,
            errors: errors[item.rowNumber]
              ? errors[item.rowNumber].join(",")
              : "",
          };

          this.rowsErrInfo.push(newItem);
        });
      }
      return this.rowsErrInfo;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    checkMappingRelationship() {
      const requireTips = []; //缺少必填字段的提醒
      const sourceTips = []; //缺少来源字段的提醒
      let flag = true;
      // 判断必填字段是否填了
      this.mappingData.forEach((item) => {
        // 判断必填字段是否填了
        if (this.requiredField.length > 0) {
          if (this.requiredField.includes(item.targetField)) {
            if (item.sourceField === "") {
              requireTips.push(item.targetField);
            }
          }
        }
        // 判断映射的来源字段在此次导入的文件中是否存在
        if (item.sourceField != "") {
          if (!this.fileDataJsonKeys.some((ele) => ele === item.sourceField)) {
            sourceTips.push(item.sourceField);
          }
        }
      });
      console.log("options", this.options);
      let warnnings = this.options.filter((item) => {
        let mark = item.assignOrRequired;
        let filterData = [];
        let sourceField = "";
        if (mark) {
          switch (item.value) {
            case "brand":
              filterData = this.mappingData.filter(
                (ele) => ele.targetField === item.label
              );

              if (filterData.length) {
                sourceField = filterData[0].sourceField;
              }
              mark =
                sourceField === "" &&
                (this.replaceData.isAssignBrand === false ||
                  (this.replaceData.isAssignBrand &&
                    !this.replaceData.assignBrand)) &&
                item.assignOrRequired;
              break;
            case "productionPlace":
              filterData = this.mappingData.filter(
                (ele) => ele.targetField === item.label
              );

              if (filterData.length) {
                sourceField = filterData[0].sourceField;
              }
              mark =
                sourceField === "" &&
                (this.replaceData.isAssignProductionPlace === false ||
                  (this.replaceData.isAssignProductionPlace &&
                    !this.replaceData.assignProductionPlace)) &&
                item.assignOrRequired;
              break;
            case "warehouseName":
              if (this.replaceData.mode === 3) {
                flag = false;
              } else {
                filterData = this.mappingData.filter(
                  (ele) => ele.targetField === item.label
                );

                if (filterData.length) {
                  sourceField = filterData[0].sourceField;
                }
                mark =
                  sourceField === "" &&
                  (this.replaceData.isAssignWarehouse === false ||
                    (this.replaceData.isAssignWarehouse &&
                      !this.replaceData.assignWarehouseId)) &&
                  item.assignOrRequired;
              }
              break;
            case "positionName":
              if ([2, 3].includes(this.replaceData.mode)) {
                flag = false;
              } else {
                filterData = this.mappingData.filter(
                  (ele) => ele.targetField === item.label
                );

                if (filterData.length) {
                  sourceField = filterData[0].sourceField;
                }
                mark =
                  sourceField === "" &&
                  (this.replaceData.isAssignPosition === false ||
                    (this.replaceData.isAssignPosition &&
                      !this.replaceData.assignPositionId)) &&
                  item.assignOrRequired;
              }
              break;
            case "batchNo":
              if ([1, 2, 3].includes(this.replaceData.mode)) {
                flag = false;
              }
              break;
            case "companyName":
              filterData = this.mappingData.filter(
                (ele) => ele.targetField === item.label
              );

              if (filterData.length) {
                sourceField = filterData[0].sourceField;
              }
              mark =
                sourceField === "" &&
                (this.replaceData.isAssignCompany === false ||
                  (this.replaceData.isAssignCompany &&
                    !this.replaceData.assignCompanyId)) &&
                item.assignOrRequired;
              break;
            default:
              break;
          }
          return mark;
        }
      });

      if (requireTips.length > 0) {
        this.$message.warning({
          message: `缺少必填字段【${requireTips.join("，")}】`,
          showClose: true,
        });
        flag = false;
      } else {
        if (sourceTips.length > 0) {
          this.$message.warning({
            message: `导入文件中缺少来源字段【${sourceTips.join("，")}】`,
            showClose: true,
          });
          flag = false;
        } else {
          if (warnnings && warnnings.length) {
            warnnings = warnnings.map((item) => {
              return `请选择【${item.label}】的映射字段或指定${item.label}`;
            });
            this.$message.warning({
              message: warnnings.join("，"),
              showClose: true,
            });
            flag = false;
          }
        }
      }
      return flag;
    },
    getErrRows(errors, rows) {
      const errRows = [];
      if (rows && rows.length) {
        rows.forEach((item) => {
          const newItem = {
            ...item,
            errors: errors[item.rowNumber],
          };
          if (newItem.errors) {
            newItem.errors = newItem.errors.join(",");
            errRows.push(newItem);
          }
        });
      }
      return errRows;
    },
    cancelHandle() {
      this.$emit("update:visible", false);
    },
    clearHandle() {
      this.$refs.refExceptInput.value = "";
      this.curSheet = "";
      this.fileDataJson = [];
      this.mappingData = [];
      this.mappingSelectOptions = [];
      this.fileDataJsonKeys = [];
    },
  },
};
</script>

<style lang="less" scoped>
.import-file {
  cursor: pointer;
}

.import-container {
  &::v-deep .el-tabs__content {
    padding: 0 !important;
  }
  &::v-deep .el-main {
    margin: 0;
    padding: 5px;
  }
  &::v-deep .el-container {
    border: 1px solid #edf2f5;
    border-top: 0;
  }
  &::v-deep .el-header {
    height: 30px !important;
    background-color: #fbfbfb;
    line-height: 30px;
    font-size: 14px;
  }
  ::v-deep .el-tabs__header {
    margin: 0px;
  }
  ::v-deep .el-header {
    background-color: white;
  }
}
.import-setting-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 10px);
  &::v-deep .el-container {
    border: 1px solid #edf2f5;
  }
  .import-remark-container {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &::v-deep .el-card__body {
      flex: auto;
      overflow: auto;
    }
  }
}
</style>
