<template>
  <div class="table-expand" v-loading="row.loading">
    <div class="table-expand-left">
      <el-image
        :z-index="3000"
        style="display: table-cell; width: 80px; height: 80px"
        :src="info.images ? info.images[0] : ''"
        :preview-src-list="info.images ? [info.images[0]] : []"
      >
        <img slot="error" class="not-available-img" :src="NotAvailableImg" />
      </el-image>
    </div>
    <div class="table-expand-right">
      <el-row :gutter="16">
        <el-col :span="5">
          <span class="table-label">配件编码：</span>
          <span class="table-value" :title="info.code">
            {{ info.code }}
          </span>
        </el-col>
        <el-col :span="5">
          <span class="table-label">配件名称：</span>
          <span class="table-value" :title="info.name">
            {{ info.name }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">性质：</span>
          <span class="table-value" :title="info.property">
            {{ info.property }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">库存数量：</span>
          <span class="table-value" :title="info.qty">{{ info.qty }}</span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">零售价：</span>
          <span class="table-value" :title="info.priceRetail">
            {{ info.prices["零售价"] }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">批价二：</span>
          <span class="table-value" :title="info.priceP2">
            {{ info.prices["批发价二"] }}
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="5">
          <span class="table-label">产地：</span>
          <span class="table-value" :title="info.productionPlace">
            {{ info.productionPlace }}
          </span>
        </el-col>
        <el-col :span="5">
          <span class="table-label">锁定数：</span>
          <span class="table-value" :title="info.lockedQty">
            {{ info.lockedQty }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">卖点：</span>
          <span class="table-value" :title="info.description">
            {{ info.description }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">车型：</span>
          <span class="table-value" :title="info.vehModel">
            {{ info.vehModel }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">平台价：</span>
          <span class="table-value" :title="info.price">
            {{ info.prices["平台价"] }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">批价三：</span>
          <span class="table-value" :title="info.priceP3">
            {{ info.prices["批发价三"] }}
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="5">
          <span class="table-label">生产码：</span>
          <span class="table-value" :title="info.productionCode">
            {{ info.productionCode }}
          </span>
        </el-col>
        <el-col :span="5">
          <span class="table-label">原厂名称：</span>
          <span class="table-value" :title="info.oeName">
            {{ info.oeName }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">规格：</span>
          <span class="table-value" :title="info.model">
            {{ info.model }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">可订货数量：</span>
          <span class="table-value" :title="info.orderQty">
            {{ info.orderQty }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">批发价：</span>
          <span class="table-value" :title="info.priceP">
            {{ info.prices["批发价"] }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">批价四：</span>
          <span class="table-value" :title="info.priceP4">
            {{ info.prices["批发价四"] }}
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="5">
          <span class="table-label">配件品牌：</span>
          <span class="table-value" :title="info.brand">
            {{ info.brand }}
          </span>
        </el-col>
        <el-col :span="5">
          <span class="table-label">汽车品牌：</span>
          <span class="table-value" :title="info.vehBrand">
            {{ info.vehBrand }}
          </span>
        </el-col>
        <el-col :span="4">
          <span class="table-label">配件类别：</span>
          <span class="table-value" :title="info.partType">
            {{ info.partType }}
          </span>
        </el-col>

        <el-col :span="4">
          <span class="table-label">配件分类：</span>
          <span class="table-value" :title="info.category">
            {{ info.category }}
          </span>
        </el-col>
        <el-col :span="3">
          <span class="table-label">批价一：</span>
          <span class="table-value" :title="info.priceP1">
            {{ info.prices["批发价一"] }}
          </span>
        </el-col>

        <el-col :span="3">
          <span class="table-label">单位：</span>
          <span class="table-value" :title="info.unit">
            {{ info.unit }}
          </span>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="5">
          <span class="table-label">划线价：</span>
          <span class="table-value" :title="info.linePrice">{{
            info.linePrice
          }}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import NotAvailableImg from "@/assets/img/store/not-available.png";

export default {
  name: "PartsTableExpand",
  props: {
    row: Object,
  },
  computed: {
    info() {
      return this.row || {};
    },
  },
  data() {
    return {
      NotAvailableImg,
    };
  },
};
</script>

<style lang="less" scoped>
.table-expand {
  width: 100%;
  height: 174px;
  box-sizing: border-box;
  padding: @padding-size-main;
  background-color: @bg-color-base;
  overflow: hidden;
  display: flex;
  .table-expand-left {
    box-sizing: border-box;
    width: 96px;
    height: 96px;
    margin-right: @margin-size-main;
    padding: @padding-size-secondary;
    border: 1px solid @border-color-base;
    background-color: #ffffff;
  }
  .table-expand-right {
    flex: 1;
    overflow: hidden;
    .el-row {
      .el-col {
        margin-bottom: @margin-size-secondary;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .table-label {
          color: @text-color-secondary;
        }
      }
    }
  }
}
</style>
