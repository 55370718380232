<template>
  <el-dialog
    v-bind="$attrs"
    append-to-body
    width="1200px"
    title="导入完成"
    v-on="$listeners"
  >
    <div v-show="!isAllFileImport">
      <span class="text-base">
        {{
          `导入结果：共导入 ${totalCount} 条，成功 ${sucCount} 条，失败 ${failCount} 条。`
        }}
      </span>
      <el-link
        v-show="sucCount && billData.billNo"
        type="primary"
        class="bill-no-container"
        @click="gotoBill"
      >
        单据号：{{ billData.billNo }}
      </el-link>
    </div>
    <div v-show="isAllFileImport">
      <div v-show="!tableData.length">
        <span class="text-base">
          {{ `导入结果：共导入 ${totalCount} 条，文件导入成功。` }}
        </span>
      </div>
      <div v-show="tableData.length">
        <span class="text-base">
          {{ `导入结果：共导入 ${totalCount} 条，文件导入失败。` }}
        </span>
      </div>
    </div>
    <div v-show="tableData.length" style="margin: 8px;">
      失败原因已为您标注，请导出文件修改后再试。
    </div>
    <RenderTable
      class="table-base"
      size="small"
      :data="tableData"
      :columns="columns"
      :tableName="tableName"
      height="400"
    />
    <!-- <el-table
      :data="tableData"
      border
      stripe
      class="table-base d_table"
      size="small"
      ref="d_table"
      height="400px"
      style="width: 100%;margin-top:0;"
    >
      <template v-for="(column, index) in columns">
        <el-table-column
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :align="column.align"
          :min-width="column.width"
          show-overflow-tooltip
          :sortable="column.sortable"
          :render="column.render"
        >
          <template slot-scope="scope">
            <span v-if="column.prop == 'index'">{{ scope.$index + 1 }}</span>
            <span
              :class="[column.prop == 'errors' ? 'text-warning' : '']"
              v-else
            >
              {{ scope.row[column.prop] }}
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table> -->
    <div class="text-warning" style="margin: 8px;">
      *为系统性能考虑，最多展示失败信息前 100 条，更多明细请导出后查看。
    </div>
    <div slot="footer" class="text-center mt-8">
      <el-button
        :disabled="!canExport"
        type="primary"
        size="small"
        plain
        @click="exportHandle"
      >
        导出
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import downloadExcel from "@/utils/downloadExcel";
import RenderTable from "@/components/RenderTable";
// import eventBus from "@/event";

const WIDTH = 160;

export default {
  name: "ImportResult",
  components: {
    RenderTable,
  },
  props: {
    title: {
      type: String,
    },
    totalErrs: {
      type: Array,
      default() {
        return [];
      },
    },
    selections: {
      type: Object,
      default() {
        return {};
      },
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isAllFileImport: {
      type: Boolean,
      default: false,
    },
    billData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableData: this.totalErrs,
      tableName: "ImportResultList",
      columns: [],
    };
  },
  computed: {
    canExport() {
      return !!this.tableData.length;
    },
    failCount() {
      return this.totalErrs && this.totalErrs.length
        ? this.totalErrs.length
        : 0;
    },
    sucCount() {
      return this.totalErrs && this.totalErrs.length
        ? this.totalCount - this.totalErrs.length
        : this.totalCount;
    },
  },
  watch: {
    selections(value) {
      // console.log("selections:value", value);
      if (value) {
        const columns = [
          {
            prop: "errors",
            label: "出错原因",
            showOverflowTooltip: true,
            width: 300,
            render(h, { row }) {
              return <span class="text-warning">{row.errors}</span>;
            },
          },
        ];
        for (const key in this.selections) {
          const column = {
            prop: `${this.selections[key]}`,
            label: `${key}`,
            showOverflowTooltip: true,
            width: WIDTH,
          };
          columns.push(column);
        }
        columns.unshift({
          prop: "index",
          label: "序号",
          type: "index",
          align: "center",
          width: 60,
          render(h, { index }) {
            // return <span>{index + 1}</span>;
            return h("span", index + 1);
          },
        });
        this.columns = columns;
      }
    },
    totalErrs(value) {
      this.tableData = value.length > 100 ? value.slice(0, 100) : [...value];
    },
  },
  methods: {
    exportHandle() {
      let dataList = this.totalErrs.map((item, i) => {
        return {
          ...item,
          index: i + 1,
        };
      });
      downloadExcel(dataList, this.columns, `${this.title} - 异常文件.xlsx`);
    },
    gotoBill() {
      this.$router.push({
        path: this.billData.path,
        query: { billId: Number(this.billData.billId) },
      });
      // eventBus.$emit("on-goto-bill", Number(this.billData.billId));
      this.closeHandle();
    },
    closeHandle() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  margin-bottom: 10px;
  border: 1px solid #edf2f5;
  .el-header {
    height: 30px !important;
    line-height: 30px;
    font-size: 14px;
  }
}

.title-card {
  /deep/ .el-card__body {
    padding: 10px 0 0 0;
  }
}

.bill-no-container {
  margin-left: 10px;
  vertical-align: top;
}
</style>
