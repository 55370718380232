<template>
  <!-- 导入组件 -->
  <el-dialog
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
    :show-close="!importLoading"
    width="1340px"
  >
    <div v-loading="importLoading">
      <el-row>
        <el-col :span="17" class="p-r-8">
          <el-form size="mini" label-position="left">
            <el-row>
              <el-col :span="16">
                <el-form-item label-width="82px" label="数据文件：">
                  <input
                    ref="refExceptInput"
                    type="file"
                    accept=".xlsx, .xlc, .xlm, .xls, .xlt, .xlw, .csv"
                    class="import-file"
                    @change="importExcel($event.target.files)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label-width="100px" label="选择工作表：">
                  <el-select v-model="curSheet" @change="selectSheet">
                    <el-option
                      v-for="(item, index) in excelSheetsName"
                      :key="index"
                      :value="item"
                      :label="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <slot />
          <el-row :gutter="10" class="import-container">
            <el-col :span="hasSetting ? 24 : 24" style="height: 100%">
              <el-container style="height: calc(100% - 10px)">
                <el-header>数据预览及字段对应</el-header>
                <el-main>
                  <ImportTable
                    ref="refImportTable"
                    :data="tabJson"
                    :selections="selections"
                    :total-options="options"
                    v-bind="$attrs"
                  />
                </el-main>
                <div class="text-font-danger" style="margin: 5px 10px;">
                  *为系统性能考虑，最多显示导入文件信息前 10 条，还有
                  {{ unshowCount }} 条未显示。
                </div>
              </el-container>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="7">
          <slot name="remark" />
        </el-col>
      </el-row>
      <div slot="footer" class="text-center mt-8">
        <el-button size="small" @click="cancelHandle">
          取消
        </el-button>
        <el-button
          :disabled="!isCanClickImport"
          type="primary"
          size="small"
          @click="importHandle"
        >
          确定
        </el-button>
      </div>
      <ImportResult
        :visible.sync="completeVisible"
        :title="title"
        :total-errs="errRows"
        :selections="selections"
        :total-count="totalCount"
        :is-all-file-import="isAllFileImport"
        v-bind="$attrs"
      />
    </div>

    <el-dialog
      :visible.sync="imporProgressVisiable"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
      width="70%"
    >
      <div style="height: 150px; text-align: center;">
        <p>导入中，请耐心等待......</p>
        <el-progress
          :text-inside="true"
          :stroke-width="22"
          :percentage="imporProgress"
        />
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import ImportTable from "./components/importTable";
import ImportResult from "./components/importResult";

import { utils } from "xlsx";
import { dateFormat, dateFormatToSecond } from "@/utils/date";

import { envConfig } from "envConfigPath";

let excelSheets = {};
let totalData = [];
let localErrRows = [],
  reqRows = [];

export default {
  name: "ImportBox",
  components: {
    ImportTable,
    ImportResult,
  },
  props: {
    hasSetting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    options: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    isCanClickImport: {
      type: Boolean,
      default: true,
    },
    isAllFileImport: {
      type: Boolean,
      default: false,
    },
    reqHandler: {
      type: Function,
      required: true,
    },
    isBill: Boolean,
    toValidIncludeOptions: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      importDialogVisible: false,
      importLoading: false,
      excelSheetsName: [],
      curSheet: "",
      tabJson: [],
      unshowCount: 0,
      selections: {},
      totalCount: 0,
      errRows: [],
      completeVisible: false,
      imporProgress: 0,
      localScope: null,
    };
  },
  computed: {
    imporProgressVisiable() {
      return !!this.imporProgress;
    },
  },
  watch: {
    "$attrs.visible"(now) {
      if (!now) {
        this.clearHandle();
      }
    },
    options: {
      handler(val) {
        console.log("options", val);
        if (val) {
          this.getRows();
        }
      },
    },
  },
  methods: {
    importExcel(files) {
      if (!files.length) {
        this.curSheet = "";
        this.excelSheetsName = [];
        this.tabJson = [];
        return;
      }
      const file = files[0] || {};
      if (!file) {
        this.curSheet = "";
        this.excelSheetsName = [];
        this.tabJson = [];
        return;
      }
      const types = file.name.split(".")[file.name.split(".").length - 1],
        fileType = ["xlsx", "xlc", "xlm", "xls", "xlt", "xlw", "csv"].some(
          (item) => item === types
        );
      if (!fileType) {
        this.$message.warning({
          message: "文件格式错误！请重新选择",
          showClose: true,
        });
        return;
      }
      this.file2Xce(file).then(async (data) => {
        this.importLoading = true;
        const fullConfig = envConfig;
        const routerPrefix = fullConfig.routerPrefix
          ? fullConfig.routerPrefix + "/worker/parseExcel.js"
          : location.origin + "/worker/parseExcel.js";
        const worker = new Worker(routerPrefix);
        let wb;
        worker.onmessage = (e) => {
          switch (e.data.t) {
            case "ready":
              break;
            case "e":
              this.importLoading = false;
              console.error(e.data.d);
              break;
            case "xlsx":
              this.importLoading = false;
              wb = JSON.parse(e.data.d);
              excelSheets = wb.Sheets;
              this.excelSheetsName = wb.SheetNames;
              this.curSheet = this.excelSheetsName[0];
              this.selectSheet(this.curSheet);
              break;
          }
        };
        worker.postMessage({
          d: data,
          b: { type: "binary" },
          t: "xlsx",
        });
      });
    },
    file2Xce(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = e.target.result;
          resolve(data);
        };
        reader.readAsBinaryString(file);
      });
    },
    selectSheet(sheet) {
      let merges = [];
      merges = excelSheets[sheet]["!merges"] || [];
      if (merges && merges.length > 0) {
        this.$message.warning({
          message: "导入Excel不能包含合并单元格",
          showClose: true,
        });
        return;
      }
      const tabJson = utils.sheet_to_json(excelSheets[sheet], {
        raw: false,
        defval: "",
      });
      // console.log("tabJson", tabJson);
      if (tabJson && tabJson.length > 0) {
        this.tabJson = tabJson.slice(0, 10).map((item) => ({ ...item }));
        totalData = tabJson.map((item) => ({ ...item }));
        this.unshowCount = totalData.length - this.tabJson.length;
        this.selections = {};
      }
      // console.log("utils", utils);
      // console.log("this.tabJson", this.tabJson);
      // console.log("this.unshowCount", this.unshowCount);
      // console.log("this.selections", this.selections);
    },
    show() {
      this.importDialogVisible = true;
    },
    async importHandle() {
      if (!this.curSheet) {
        this.$message.warning({
          message: "请选择导入文件",
          showClose: true,
        });
        return;
      }
      if (this.$refs.refImportTable.checkSelections()) {
        this.importLoading = true;
        await this.getRows();
        console.log("reqRows", reqRows);
        const rows = reqRows.map((item) => {
          return { ...item };
        });
        this.reqHandle([...rows])
          .then((errors) => {
            this.errRows = [
              ...localErrRows,
              ...this.getErrRows({ ...errors }, [...rows]),
            ];
            console.log("errRows", this.errRows);
            let isRefresh = false;
            this.totalCount = totalData.length;
            if (this.errRows.length) {
              isRefresh =
                this.totalCount - this.errRows.length > 0 ? true : false;
              this.completeVisible = true;
            } else {
              let sucCount = this.totalCount - this.errRows.length;
              let msgSuc = `导入结果：共导入 ${this.totalCount} 条，成功 ${sucCount} 条，失败 ${this.errRows.length} 条。`;
              this.$message.success(msgSuc);
              isRefresh = true;
            }
            this.$emit("import", isRefresh);
            localErrRows = [];
            reqRows = [];
            this.cancelHandle();
            this.imporProgress = 0;
            this.importLoading = false;
          })
          .catch(() => {
            this.importLoading = false;
            localErrRows = [];
            reqRows = [];
          });
      }
    },
    async getRows() {
      await this.sleep(100);
      const selectOptions = [];
      const keys = Object.keys(this.selections) || [];
      if (keys && keys.length && this.options && this.options.length) {
        this.options.forEach((item) => {
          keys.forEach((key) => {
            if (this.selections[key] == item.value) {
              selectOptions.push({ ...item, importLabel: key });
            }
          });
        });
      }
      console.log("totalData", totalData);
      console.log("selectOptions", selectOptions);
      reqRows = [];
      if (totalData.length && selectOptions.length) {
        totalData.forEach((item, index) => {
          const row = {},
            errors = [];
          const keys = Object.keys(item) || [];
          if (keys.length) {
            keys.forEach((key) => {
              selectOptions.forEach((option) => {
                if (option.importLabel == key) {
                  let data = item[key];
                  if (option.formate) {
                    data = option.formate(item[key]);
                  } else {
                    switch (option.type) {
                      case "number":
                        data = Number.isNaN(
                          Number(String(data).replace(/,/g, ""))
                        )
                          ? data
                          : Number(String(data).replace(/,/g, ""));
                        break;
                      case "date":
                        data = data ? dateFormat(data) : "";
                        break;
                      case "time":
                        data = data ? dateFormatToSecond(data) : "";
                        break;
                      case "string":
                      default:
                        data = data ? String(data).trim() : "";
                        break;
                    }
                  }
                  row[option.value] = data;
                  if (this.toValidIncludeOptions) {
                    const optionErrors =
                      option.checkValue &&
                      option.checkValue(
                        data,
                        option,
                        this.toValidIncludeOptions[option.value]
                      );

                    if (optionErrors?.length) {
                      errors.push(optionErrors.join(","));
                    }
                  }
                }
              });
            });
          }
          row.rowNumber = index + 1;
          if (errors.length) {
            row.errors = errors.join(",");
            localErrRows.push(row);
          } else {
            // console.log("push", row);
            reqRows.push(row);
          }
        });
      }
    },
    async reqHandle(rows) {
      if (this.isBill && this.offset) {
        const resData = [];
        const count = Math.ceil(rows.length / this.offset);
        const offset = Math.floor(100 / count);
        if (count > 1) {
          const res1 = await this.reqHandler(rows.slice(0, this.offset));
          resData.push(res1.errors);
          for (let i = 0; i < count; i++) {
            const params = rows.slice(
              (i + 1) * this.offset,
              (i + 2) * this.offset
            );
            const res = await this.reqHandler(params);
            this.imporProgress += offset;
            resData.push(res?.errors || []);
          }
        } else {
          const res1 = await this.reqHandler(rows);
          resData.push(res1?.errors || []);
        }
        let errors = {};
        resData.forEach((item) => {
          errors = { ...errors, ...item };
        });
        return errors;
      } else if (this.offset) {
        const resData = [];
        const count = Math.ceil(rows.length / this.offset);
        const offset = Math.floor(100 / count);
        console.log("count", count);
        console.log("offset", offset);

        for (let i = 0; i < count; i++) {
          const params = rows.slice(i * this.offset, (i + 1) * this.offset);
          const res = await this.reqHandler(params);
          this.imporProgress += offset;
          resData.push(res.errors || []);
        }
        let errors = {};
        resData.forEach((item) => {
          errors = { ...errors, ...item };
        });
        return errors;
      } else {
        const res = await this.reqHandler(rows);
        return res?.errors || [];
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getErrRows(errors, rows) {
      const errRows = [];
      if (rows && rows.length) {
        rows.forEach((item) => {
          const newItem = {
            ...item,
            errors: errors[item.rowNumber],
          };
          if (newItem.errors) {
            newItem.errors = newItem.errors.join(",");
            errRows.push(newItem);
          }
        });
      }
      return errRows;
    },
    cancelHandle() {
      this.$emit("update:visible", false);
    },
    clearHandle() {
      this.$refs.refExceptInput.value = "";
      this.curSheet = "";
      this.tabJson = [];
    },
  },
};
</script>

<style lang="less" scoped>
.import-file {
  cursor: pointer;
}
.import-container {
  height: 482px;
  background: #f2f2f289;
  .el-header {
    height: 30px !important;
    background-color: #fbfbfb;
    line-height: 30px;
    font-size: 14px;
  }
}
.text-font-danger {
  color: #ff424d;
}
.el-main {
  padding: 4px;
}
</style>
