<template>
  <RenderTable
    size="small"
    class="table-base d_table"
    ref="d_table"
    height="400px"
    style="width: 100%;margin-top:0;"
    :data="tableData"
    :columns="columns"
  />
</template>

<script>
import HtSelect from "../../htSelect/index.vue";
import RenderTable from "@/components/RenderTable";

export default {
  name: "ImportTable",
  components: {
    HtSelect,
    RenderTable,
  },
  props: {
    requiredField: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    mappingData: {
      type: Array,
      required: true,
    },
    mappingSelectOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      selectionList: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          label: "目标字段",
          prop: "targetField",
          minWidth: "80px",
          render: (value, { row }) => {
            if (this.requiredField.includes(row.targetField)) {
              return (
                <div>
                  <span style={{ color: "red" }}>*</span>
                  <span style={{ color: "#6bd3fd" }}>{row.targetField}</span>
                </div>
              );
            } else {
              return <span>{row.targetField}</span>;
            }
          },
        },
        {
          label: "来源字段",
          prop: "sourceField",
          minWidth: "80px",
          render: (value, { row }) => {
            return (
              <HtSelect
                clearable
                filterable
                size="mini"
                name="value"
                id="value"
                v-model={row.sourceField}
                options={this.getOptions(row.sourceField)}
                on-change={this.changeSelect}
              ></HtSelect>
            );
          },
        },
      ];
    },
  },
  watch: {
    mappingData: {
      deep: true,
      immediate: true,
      handler(value) {
        this.tableData = JSON.parse(JSON.stringify(value));
        this.selectionList = [];
        this.tableData.forEach((item) => {
          if (item.sourceField) {
            this.selectionList.push(item.sourceField);
          }
        });
      },
    },
  },
  methods: {
    // 组装下拉数据
    getOptions(value) {
      const options = [
        ...this.mappingSelectOptions.filter(
          (item) => !this.selectionList.includes(item.value)
        ),
      ];

      return options;
    },
    changeSelect() {
      this.$emit("updateMappingData", this.tableData);
    },
  },
};
</script>

<style lang="less" scoped>
.d_table {
  /deep/ .el-table__body-wrapper {
    height: 364px !important;
  }
  /deep/.el-table__body-wrapper.is-scrolling-left {
    height: 364px !important;
    max-height: 364px !important;
  }
  // /deep/.el-table__body-wrapper.is-scrolling-none {
  //   height: 364px !important;
  // }
  /deep/.el-table__row td,
  /deep/th {
    height: 32px;
  }
}
.el-table td,
.el-table th {
  padding: 2px 0 !important;
}
</style>
