<template>
  <el-dialog
    v-bind="$attrs"
    append-to-body
    width="1200px"
    :title="title"
    v-on="$listeners"
    class="import-result-box"
  >
    <div
      v-if="isAllFileImport && totalErrs.length == 0"
      class="successContainer"
    >
      <div style="margin-bottom: 10px;">
        <div>共导入{{ rowsErrInfo.length }}条数据。</div>
        <div v-if="isBillType">生成单据号：{{ billData.billNo }}</div>
        <div v-if="isBillType">是否打开单据详情</div>
      </div>
      <div v-if="isBillType" class="dialog-footer-align width-full">
        <el-button type="primary" size="mini" plain @click="gotoBill">
          打开
        </el-button>
        <el-button type="primary" size="mini" plain @click="closeHandle">
          取消
        </el-button>
      </div>
    </div>
    <div v-if="totalErrs.length > 0">
      <div class="headerMark">
        <div class="mark">
          共读取{{ rowsErrInfo.length }}条，
          <span class="success">数据检查通过{{ sucCount }}条，</span>
          <span class="fail">失败{{ failCount }}条。</span>
        </div>
        <div class="mark">失败原因已为您标注，请导出文件修改后再试。</div>
      </div>
      <RenderTable
        class="table-base"
        size="small"
        :data="tableData"
        :columns="columns"
        :tableName="tableName"
        height="400"
      />
      <div class="text-danger" style="margin: 5px;">
        *为系统性能考虑，最多展示失败信息前 100 条，更多明细请导出后查看。
      </div>
      <div class="dialog-footer-align">
        <el-button
          :disabled="!canExport"
          type="primary"
          size="mini"
          plain
          @click="exportHandle"
        >
          导出
        </el-button>
      </div>
    </div>
    <div v-if="!isAllFileImport && totalErrs.length == 0">
      <div class="successContainer">
        <div style="margin-bottom: 10px;">
          共导入{{ rowsErrInfo.length }}条数据。
        </div>
      </div>
      <div class="dialog-footer-align">
        <el-button type="primary" size="mini" plain @click="closeHandle">
          关闭
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import downloadExcel from "@/utils/downloadExcel";
import RenderTable from "@/components/RenderTable";
// import eventBus from "@/event";

const WIDTH = 160;

export default {
  name: "ImportResult",
  components: {
    RenderTable,
  },
  props: {
    // 导入出错的数据
    totalErrs: {
      type: Array,
      default() {
        return [];
      },
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    //单据累（销售退货）-isAllFileImport为true
    //期初数据导入-isAllFileImport为false
    isAllFileImport: {
      type: Boolean,
      default: false,
    },
    billData: {
      type: Object,
      default() {
        return {};
      },
    },
    resultColumn: {
      type: Array,
      required: true,
    },
    // 导入的所有数据,每条数据带有出错信息
    rowsErrInfo: {
      type: Array,
      required: true,
    },
    isBillType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableData: this.totalErrs,
      tableName: "ImportResultList",
      columns: [],
    };
  },
  computed: {
    title() {
      if (this.totalErrs.length > 0) {
        return "导入结果";
      } else {
        return "导入成功";
      }
    },
    canExport() {
      return !!this.tableData.length;
    },
    failCount() {
      return this.totalErrs && this.totalErrs.length
        ? this.totalErrs.length
        : 0;
    },
    sucCount() {
      return this.totalErrs && this.totalErrs.length
        ? this.totalCount - this.totalErrs.length
        : this.totalCount;
    },
  },
  watch: {
    resultColumn(value) {
      if (value.length > 0) {
        const columns = [
          {
            prop: "errors",
            label: "出错原因",
            showOverflowTooltip: true,
            width: 300,
            render: (value, { row }) => {
              return <span class="text-danger">{row.errors}</span>;
            },
          },
          {
            prop: "rowNumber",
            label: "行号",
            width: 50,
          },
        ];

        value.forEach((item) => {
          columns.push({
            ...item,
            prop: item.value,
            showOverflowTooltip: true,
            width: WIDTH,
          });
        });

        columns.unshift({
          prop: "index",
          label: "序号",
          type: "index",
          align: "center",
          width: 60,
          render(h, { index }) {
            return h("span", index + 1);
          },
        });
        this.columns = columns;
      }
    },
    totalErrs(value) {
      this.tableData = value.length > 100 ? value.slice(0, 100) : [...value];
    },
  },
  methods: {
    exportHandle() {
      let dataList = this.totalErrs.map((item, i) => {
        return {
          ...item,
          index: i + 1,
        };
      });
      downloadExcel(dataList, this.columns, `${this.title} - 异常文件.xlsx`);
    },
    gotoBill() {
      this.$router.push({
        path: this.billData.path,
        query: { billId: Number(this.billData.billId) },
      });
      // eventBus.$emit("on-goto-bill", Number(this.billData.billId));
      this.closeHandle();
    },
    closeHandle() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.import-result-box {
  .headerMark {
    margin-bottom: 10px;
    .mark {
      font-size: 18px;
      .success {
        color: #12abfd;
      }
      .fail {
        color: red;
      }
    }
  }

  .successContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
  }
  .dialog-footer-align {
    text-align: center;
  }
}
</style>
