<template>
  <!-- <el-table
    :data="tableData"
    border
    stripe
    class="table-base d_table"
    size="small"
    ref="d_table"
    height="400px"
    style="width: 100%;margin-top:0;"
  >
    <template v-for="(column, index) in columns">
      <el-table-column
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :align="index == 0 ? 'center' : ''"
        :min-width="column.width"
        show-overflow-tooltip
        :sortable="column.sortable"
        :render="column.render"
      >
        <template slot-scope="scope">
          <span v-if="index > 0 && scope.row[column.prop] == 'selction'">
            <HtSelect
              clearable
              size="mini"
              name="label"
              id="value"
              v-model="selections[column.prop]"
              :options="getCurOptions(selections[column.prop])"
            ></HtSelect>
          </span>
          <span v-else>{{ scope.row[column.prop] }}</span>
        </template>
      </el-table-column>
    </template>
  </el-table> -->
  <RenderTable
    size="small"
    class="table-base d_table"
    ref="d_table"
    height="400px"
    style="width: 100%;margin-top:0;"
    :data="tableData"
    :columns="columns"
  />
</template>

<script>
import { dateFormat, dateFormatToSecond } from "@/utils/date.js";
import HtSelect from "../../htSelect/index.vue";
import RenderTable from "@/components/RenderTable";

// const selctionSymbol = Symbol("selction");
const selctionSymbol = "selction";
const WIDTH = "160";

export default {
  name: "ImportTable",
  components: {
    HtSelect,
    RenderTable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    selections: {
      type: Object,
      required: true,
    },
    totalOptions: {
      type: Array,
      required: true,
    },
    replaceData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      tableData: [],
      columns: [],
      autoSelection: [],
    };
  },
  computed: {
    selectableOptions() {
      return this.totalOptions.filter((item) => {
        return !Object.keys(this.selections).some(
          (key) => this.selections[key] === item.value
        );
      });
    },
  },
  watch: {
    data(value) {
      // console.log("tableData", value);
      if (value.length) {
        this.getSheetData(value);
      } else {
        this.tableData = [];
        this.columns = [];
      }
    },
    autoSelection() {
      if (
        this.autoSelection &&
        this.autoSelection.length &&
        this.selectableOptions &&
        this.selectableOptions.length
      ) {
        this.autoSelection.forEach((item) => {
          this.selectableOptions.forEach((option) => {
            // eslint-disable-next-line no-useless-escape
            const reg = /[\(（\)）]/g;
            if (
              option.label === item ||
              (reg.test(option.label) &&
                reg.test(item) &&
                option.label.replace(reg, "") === item.replace(reg, ""))
            ) {
              this.$set(this.selections, item, option.value);
            }
          });
        });
      }
    },
  },
  methods: {
    getCurOptions(value) {
      console.log("selections", this.selections);
      console.log("value", value);
      console.log("this.totalOptions", this.totalOptions);
      console.log("this.selectableOptions", this.selectableOptions);
      const options = [
        ...this.totalOptions.filter((item) => item.value === value),
        ...this.selectableOptions,
      ];
      return options;
    },
    getSheetData(data) {
      const body = data ? [...data] : [];
      const firstData = { title: "导入目标字段" };
      const dataHeader = [...Object.keys(data[0])];
      this.autoSelection = [...dataHeader];
      dataHeader.forEach((key) => {
        firstData[key] = selctionSymbol;
      });
      // console.log("firstData", firstData);
      // console.log("body", body);
      body.forEach((item, index) => {
        item.title = index + 1;
        const keys = Object.keys(item);
        if (keys?.length) {
          keys.forEach((key) => {
            if (key.includes("日期")) {
              item[key] = dateFormat(item[key]);
            } else if (key.includes("时间")) {
              item[key] = dateFormatToSecond(item[key]);
            }
          });
        }
      });
      body.unshift({ ...firstData });
      this.tableData = [...body];
      console.log("tableData", this.tableData);
      // console.log("dataHeader", dataHeader);
      const header = dataHeader.map((item) => {
        return {
          label: item,
          prop: item,
          width: WIDTH,
          render(h, { column, row }) {
            if (row[column.label] === selctionSymbol) {
              return (
                <HtSelect
                  clearable
                  size="mini"
                  name="label"
                  id="value"
                  v-model={this.selections[item]}
                  options={this.getCurOptions(this.selections[item])}
                ></HtSelect>
              );
            }
            return h("span", row[column.label]);
          },
        };
      });
      header.unshift({
        label: "上传文件字段",
        prop: "title",
        align: "center",
        width: "120",
      });
      // console.log("header", header);
      this.columns = [...header];
      console.log("columns", this.columns);
    },
    checkSelections() {
      if (
        this.selectableOptions.some((item) => {
          return item.required;
        })
      ) {
        let wanings = this.selectableOptions.filter((item) => {
          let flag = item.required;
          switch (item.value) {
            case "brand":
              flag =
                !(
                  this.replaceData.isAssignBrand && this.replaceData.assignBrand
                ) && item.required;
              break;
            case "productionPlace":
              flag =
                !(
                  this.replaceData.isAssignProductionPlace &&
                  this.replaceData.assignProductionPlace
                ) && item.required;
              break;
            case "warehouseName":
              if (this.replaceData.mode === 3) {
                flag = false;
              } else {
                flag =
                  !(
                    this.replaceData.isAssignWarehouse &&
                    this.replaceData.assignWarehouseId
                  ) && item.required;
              }
              break;
            case "positionName":
              if ([2, 3].includes(this.replaceData.mode)) {
                flag = false;
              } else {
                flag =
                  !(
                    this.replaceData.isAssignPosition &&
                    this.replaceData.assignPositionId
                  ) && item.required;
              }
              break;
            case "batchNo":
              if ([1, 2, 3].includes(this.replaceData.mode)) {
                flag = false;
              }
              break;
            case "companyName":
              flag =
                !(
                  this.replaceData.isAssignCompany &&
                  this.replaceData.assignCompanyId
                ) && item.required;
              break;
            default:
              break;
          }
          return flag;
        });
        // console.log("this.selectableOptions,", this.selectableOptions);
        // console.log("wanings,", wanings);
        if (wanings && wanings.length) {
          wanings = wanings.map((item) => {
            return `缺少${item.label}`;
          });
          this.$message.warning({
            message: wanings.join("，"),
            showClose: true,
          });
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.d_table {
  /deep/ .el-table__body-wrapper {
    height: 364px !important;
  }
  /deep/.el-table__body-wrapper.is-scrolling-left {
    height: 364px !important;
    max-height: 364px !important;
  }
  // /deep/.el-table__body-wrapper.is-scrolling-none {
  //   height: 364px !important;
  // }
  /deep/.el-table__row td,
  /deep/th {
    height: 32px;
  }
}
.el-table td,
.el-table th {
  padding: 2px 0 !important;
}
</style>
