import request, { SERVICE_MODULE_MAP } from "@/utils/request";

export function reqImportParts(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/goods/import",
    method: "post",
    data,
  });
}

export function getSettingsTables(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/table-settings",
    method: "get",
    params,
  });
}

export function postSettingsTables(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/settings/table-settings",
    method: "post",
    data,
  });
}
