<template>
  <SearchTemplate
    :formData="formData"
    placeholderText="配件名称/编码/规格/车型/生产码等关键字"
    labelWidth="80px"
    @handleQuery="handleQuery"
    @handleReset="handleReset"
  >
    <el-button
      v-if="isShelvesButton"
      slot="right"
      type="warning"
      plain
      size="small"
      @click="deleteAllData"
    >
      删除全部数据
    </el-button>
    <el-button
      v-if="isShelvesButton && this.$store.state.profile.erpBinded"
      slot="right"
      type="primary"
      plain
      size="small"
      @click="goShelves"
    >
      去上下架配件
    </el-button>
    <el-button
      slot="right"
      type="primary"
      plain
      size="small"
      v-if="!$store.state.profile.erpBinded"
      @click="handleImport"
    >
      Excel导入商品
    </el-button>
    <template slot="more">
      <el-form-item label="配件分类">
        <el-input
          v-model.trim="formData.category"
          clearable
          placeholder="配件分类"
        ></el-input>
      </el-form-item>
      <el-form-item label="配件类别">
        <el-input
          v-model.trim="formData.partType"
          clearable
          placeholder="配件类别"
        ></el-input>
      </el-form-item>
      <el-form-item label="配件品牌">
        <el-input
          v-model.trim="formData.brand"
          clearable
          placeholder="配件品牌"
        ></el-input>
      </el-form-item>
      <el-form-item label="配件属性">
        <el-input
          v-model.trim="formData.partProperty"
          clearable
          placeholder="配件属性"
        ></el-input>
      </el-form-item>
      <el-form-item label="产地">
        <el-input
          v-model.trim="formData.productionPlace"
          clearable
          placeholder="产地"
        ></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-select v-model="formData.image">
          <el-option
            v-for="item in imageOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品标签">
        <el-select
          v-model="formData.label"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="汽车品牌">
        <el-input
          v-model.trim="formData.vehBrand"
          clearable
          placeholder="汽车品牌"
        ></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input
          class="input-with-select"
          v-model.trim="formData.priceValue"
          clearable
          placeholder="金额"
        >
          <el-select
            slot="prepend"
            v-model="formData.priceField"
            placeholder="请选择"
          >
            <el-option
              v-for="item in priceFieldOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            slot="prepend"
            v-model="formData.priceExp"
            placeholder="请选择"
          >
            <el-option
              v-for="item in priceExpOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item label="库存">
        <el-input
          class="input-with-select"
          v-model.trim="formData.qtyValue"
          clearable
          placeholder="数量"
        >
          <el-select
            slot="prepend"
            v-model="formData.qtyField"
            placeholder="请选择"
          >
            <el-option
              v-for="item in qtyFieldOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            slot="prepend"
            v-model="formData.qtyExp"
            placeholder="请选择"
          >
            <el-option
              v-for="item in qtyExpOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
    </template>
  </SearchTemplate>
</template>

<script>
import { erpUrl } from "envConfigPath";
import { getToken, deleteParts } from "@/api/account";
import { getLabelList } from "@/api/m8";
import SearchTemplate from "@/components/search/SearchTemplate.vue";

export default {
  name: "PartsSearch",
  components: {
    SearchTemplate,
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    isShelvesButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      zeroPriceList: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "2",
          label: "否",
        },
      ],
      priceFieldOptions: [
        {
          value: "retail",
          label: "零售价",
        },
        {
          value: "alliance",
          label: "平台价",
        },
        {
          value: "p",
          label: "批发价",
        },
        {
          value: "p1",
          label: "批发价一",
        },
        {
          value: "p2",
          label: "批发价二",
        },
        {
          value: "p3",
          label: "批发价三",
        },
        {
          value: "p4",
          label: "批发价四",
        },
      ],
      priceExpOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "=",
          label: "等于",
        },
        {
          value: ">",
          label: "大于",
        },
        {
          value: "<",
          label: "小于",
        },
        {
          value: ">=",
          label: "大于等于",
        },
        {
          value: "<=",
          label: "小于等于",
        },
      ],
      // 库存字段 qty:实际库存;lockedQty:锁定库存;orderQty:可订货库存数量
      qtyFieldOptions: [
        {
          value: "qty",
          label: "实际库存",
        },
        {
          value: "lockedQty",
          label: "锁定库存",
        },
        {
          value: "orderQty",
          label: "可订货库存数量",
        },
      ],
      // 库存对比类型 1,等于 2.大于 3.小于 4.大于等于 5.小于等于 0.查询全部
      qtyExpOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "=",
          label: "等于",
        },
        {
          value: ">",
          label: "大于",
        },
        {
          value: "<",
          label: "小于",
        },
        {
          value: ">=",
          label: "大于等于",
        },
        {
          value: "<=",
          label: "小于等于",
        },
      ],
      imageOptions: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "已上传",
        },
        {
          value: "2",
          label: "未上传",
        },
      ],
      cleanedOptions: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "已标准化",
        },
        {
          value: "2",
          label: "未标准化",
        },
      ],
      options: [],
    };
  },
  methods: {
    handleQuery() {
      this.$emit("handleQuery");
    },
    handleReset() {
      this.$emit("handleReset");
    },
    handleImport() {
      this.$emit("handleImport");
    },
    // 去上下架配件
    goShelves() {
      getToken().then((res) => {
        // window.open(`${res.addr}?token=${res.token}`, "_blank");
        //window.open窗口最大化
        var w = screen.availWidth;
        var h = screen.availHeight;
        window.open(
          `${res.addr}?token=${res.token}`,
          "",
          "width=" + w + ",height=" + h + ",top=0,left=0,status=yes"
        );
      });
    },
    remoteMethod(query) {
      getLabelList({
        name: query,
        type: "part",
      }).then((res) => {
        this.options = res;
      });
    },
    deleteAllData() {
      this.$confirm("将删除平台上所有配件资料和库存数据，是否确认?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteParts({
            allParts: true,
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.handleQuery();
          });
        })
        .catch(() => {
          this.handleQuery();
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .form-search {
  // padding: @padding-size-secondary @padding-size-secondary 0;
  background-color: #ffffff;
  .el-form-item {
    margin-bottom: 8px;
  }
}
.el-button {
  .icon-Down,
  .icon-Up {
    font-size: 12px;
  }
  .el-icon-arrow-down,
  .el-icon-arrow-up {
    font-size: 12px;
  }
}
</style>
